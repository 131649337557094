//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-48:_3392,_3072,_6236,_2652,_1304,_4444,_9996,_6808;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-48')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-48', "_3392,_3072,_6236,_2652,_1304,_4444,_9996,_6808");
        }
      }catch (ex) {
        console.error(ex);
      }